import type { HexaColor } from '@/interfaces/Colors.interfaces'

export const getRandomColor = (): HexaColor => {
  const letters = '0123456789ABCDEF'.split('')
  let color = ''
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return `#${color}`
}

export const textColorFromBg = (color?: string): HexaColor => {
  if (color) {
    const hex = color.substring(1, 7)
    const coefs = [0.21, 0.72, 0.07]
    let l = 0
    for (let i = 0; i < 3; i++) {
      const from = i * 2
      const c = parseInt(hex.substring(from, from + 2), 16) * coefs[i]
      l += c
    }
    const lightness = l / 255
    if (lightness > 0.5) {
      return '#000000'
    }
  }
  return '#ffffff'
}
